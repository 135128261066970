import React from 'react';

import LayoutPanel from 'components/layout/layoutPanel';

import Link from 'components/common/Link';
import { LinkModal } from 'kh-common-components';
import { LinkAnchor } from 'components/common/LinkAnchor';
import Image from 'components/common/Image';

import heroBanner from 'images/natural-gas/hero-cupcake.png';
import heroBannerWA from 'images/natural-gas/flowmoji-wa-cyan.png';

import { useToggleRac50Expired } from 'constants/hooks/marketingEventToggles';
import { modalContents } from 'constants/modal-content';

function SignUpNgInfo (props) {

  const isPromoRac50Expired = useToggleRac50Expired();

  return (
    <LayoutPanel background={props.panelBackground}
                padding={props.panelPadding}
                {...props}
                id="signup-ng-info">
        <div className="container">
            <div className="row mer-panel align-items-center justify-content-around">
                <div className="col-10 col-sm-7 col-lg-5 offset-lg-1 order-2 order-sm-1 p-0 mer-text--size-lg">
                    <div className="content">
                        {isPromoRac50Expired ? (
                            <>
                                <h1 className="mer-typography--headline2">Save 35% off your gas usage charges for 12 months.<sup>~</sup></h1>
                                <h6>Switch to WA's local gas supplier and enjoy ongoing discounts, perks & rewards.</h6>
                            </>
                         ) : (
                            <>
                                <h1>Switch to award-winning local service.</h1>
                                <p>We've received Canstar Blue's award for Most Satisfied Customers - WA Natural Gas for the second year in a row!</p>
                            </>
                         )}

                        <div className="mer-panel__actions mer-button-group">
                            <Link text="Sign up"
                                link="/sign-up"
                                linkClass="mer-button mer-button--primary" />
                            { props.ctaLinkAnchor && 
                                <LinkAnchor 
                                    to="/service-availability#products-info" 
                                    title="View plans" 
                                    stripHash={true} 
                                    offset={-100} 
                                    className="mer-button mer-button--secondary"
                                    />
                            }
                            { !props.ctaLinkAnchor && 
                                    <Link text="View plans"
                                    link="#products-info"
                                    linkClass="mer-button mer-button--secondary" />
                            }
                        </div>

                        { isPromoRac50Expired && (
                            <p className="mer-typography--body2">
                                <small>
                                    <LinkModal 
                                        modalContents={modalContents}
                                        useModal="true"
                                        displayContent="~Terms and conditions apply"
                                        title="Monthly Energiser terms & conditions"
                                        modalTitle="Monthly Energiser terms and conditions"
                                        modalContentName="plan_details_and_terms_537"
                                        okayButtonLabel="Close"
                                    />
                                </small>
                            </p>
                        )}
                    </div>
                </div>
                <div className="mer-panel__images hero-banner col-8 col-sm-5 order-1 order-sm-2 p-0">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-10 col-lg-9 text-center p-0">
                            {isPromoRac50Expired ? (
                                <img src={heroBannerWA} 
                                    className="mer-panel__image"
                                    alt="Flowmoji WA" />
                            ) : (
                                <img src={heroBanner} 
                                    className="mer-panel__image"
                                    alt="YAY cupcakes" />
                            )} 
                            
                        </div>
                    </div>
                    <div className={`row align-items-center justify-content-center ${isPromoRac50Expired ? 'd-none' : ''}`}>
                        <div className="col-5 col-sm-3 py-0">
                                <a href="#awards-info" 
                                    title="Click to view 2023 Most satisfied customers WA award" 
                                    className="mer-link--hover-none">
                                    <Image className="mer-panel__image"
                                        src="/canstar-ng-23-customers.png"
                                        alt="2023 Most satisfied customers WA award"
                                    />
                                </a>
                        </div>
                        <div className="col-5 col-sm-3 py-0">
                            <a href="#awards-info" 
                                title="Open to view 2022 Most satisfied customers WA award" 
                                className="mer-link--hover-none">
                                <Image className="mer-panel__image"
                                    src="/canstar-ng-22.png"
                                    alt="2022 Most satisfied customers WA award"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutPanel>
  )
}

export default SignUpNgInfo;