import React from 'react';
import Image from 'components/common/Image';
import LayoutPanel from 'components/layout/layoutPanel';
import { LinkModal } from 'kh-common-components';
import { modalContents } from '../../constants/modal-content';
import racLogo from 'images/rac/natural-gas/logo-rac.png';


function ProductHighlightNew (props) {

    return (
        <LayoutPanel background={props.panelBackground}
                     padding={props.panelPadding}>
            <div className="container">
                <div className="row justify-content-center align-items-start mer-text--size-lg mer-border" style={{'borderRadius':'2em', 'borderWidth':'2px'}}>
                    <div className="col-12 p-0 mer-text--size-lg">
                        <div className="content text-center">
                            <h5 className="mer-color--primary-cyan d-inline-block mer-bg--ui-light mer-px-de" style={{'position':'relative', 'top':'-0.75em'}}>Why choose Kleenheat?</h5>
                        </div>
                    </div>
                    
                    <div className="col-12 col-md-10 col-lg-4">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-3 col-md-3 col-lg-6 mer-px-de">
                                <Image className="mer-panel__image"
                                       src="/icons/flowmoji/flowmoji-wa.svg"
                                       alt="Flowmoji Western Australia"
                                />
                            </div>
                            <div className="col-8 col-sm-6 col-md-8 col-lg-9 text-lg-center">
                                <h5 className="my-0">WA born & bred</h5>
                                <p>We’ve been at home in WA for over 65 years.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-10 col-lg-4">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-3 col-md-3 col-lg-6 mer-px-de">
                                <Image className="mer-panel__image"
                                    src="/icons/flowmoji/flowmoji-piggybank.svg"
                                    alt="Flowmoji piggybank"
                                />
                            </div>
                            <div className="col-8 col-sm-6 col-md-8 col-lg-9 text-lg-center">
                                <h5 className="my-0">Discounts, perks & rewards</h5>
                                <p>Enjoy exclusive savings through our partnership with RAC & Flybuys.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-10 col-lg-4">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-3 col-md-3 col-lg-6 mer-px-de">
                                <Image className="mer-panel__image"
                                    src="/canstar-ng-23.png"
                                    alt="Canstar 2023"
                                />
                            </div>
                            <div className="col-8 col-sm-6 col-md-8 col-lg-9 text-lg-center">
                                <h5 className="my-0">Award-winning service</h5>
                                <p>Our local team are here to make gas easy for you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPanel>
    );
}

export default ProductHighlightNew;